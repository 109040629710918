<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t('fence.name')">
              <el-input :placeholder="$t('fence.name')" v-model="formInline.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('fence.type')">
              <el-select :placeholder="$t('fence.type')" v-model="formInline.type">
                <el-option :label="$t('fence.normal')" value="1"></el-option>
                <el-option :label="$t('fence.flood')" value="2"></el-option>
                <el-option :label="$t('fence.safety')" value="3"></el-option>
                <el-option :label="$t('fence.construction')" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('fence.alarm_level')">
              <el-select :placeholder="$t('fence.alarm_level')" v-model="formInline.level">
                <el-option :label="$t('fence.red')" value="1"></el-option>
                <el-option :label="$t('fence.orange')" value="2"></el-option>
                <el-option :label="$t('fence.yellow')" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="onSubmit" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="handleReset" type="primary">{{ $t('device.reset') }}</el-button>
              <!--              <el-button @click="onSubmit" type="primary">导出</el-button>-->
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-row :gutter="20">
        <el-col :span="4" v-if="false">
          <div class="grid-content bg-purple">
            <el-tree
                :data="clientData"
                :default-expand-all="true"
                :props="defaultProps"
                @check="handleNodeClick"
                highlight-current
                node-key="id"
                ref="tree"
                show-checkbox
            ></el-tree>
          </div>
        </el-col>
        <el-col>
          <div class="grid-content bg-purple">
            <el-button @click="newFence" type="primary" v-if="$store.state.pageAction.indexOf('fence.add')> -1">
              {{ $t('fence.add') }}
            </el-button>
            <el-table
                :data="fenceData"
                :default-sort="{prop: 'date', order: 'descending'}"
                border
                stripe
                style="width: 100%"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column :label="$t('fence.name')" prop="name" width="200"></el-table-column>
              <el-table-column :label="$t('fence.radius')" prop="radius" width="200"></el-table-column>
              <el-table-column :label="$t('fence.alarm')" prop="alarm" width="200"></el-table-column>
              <el-table-column :label="$t('fence.alarm_level')" prop="level" width="200"></el-table-column>
              <el-table-column :label="$t('fence.type')" prop="type" width="200"></el-table-column>
              <el-table-column :label="$t('fence.duration')" width="400">
                <template #default="scope">{{ scope.row.start_time }}~{{ scope.row.end_time }}</template>
              </el-table-column>
              <el-table-column :label="$t('fence.status')" prop="status" width="200"></el-table-column>
              <el-table-column :label="$t('device.vesting')" prop="client_name" width="200"></el-table-column>
              <el-table-column :label="$t('fence.creator')" prop="username" width="200"></el-table-column>
              <el-table-column :label="$t('device.creation_time')" prop="create_at" width="200"></el-table-column>
              <el-table-column :label="$t('device.operating')" prop="update" width="300" fixed="right">
                <template #default="scope">
                  <el-button @click="handleList(scope.row.imei_list)" type="text" size="small"
                             v-if="$store.state.pageAction.indexOf('device.devices')> -1">{{
                      $t('device.devices')
                    }}
                  </el-button>
                  <el-button @click="handleEdit(scope.row.id)" type="text" size="small"
                             v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{
                      $t('device.edit')
                    }}
                  </el-button>
                  <el-button @click="handleRemove(scope.row.id)" type="text" size="small"
                             v-if="$store.state.pageAction.indexOf('device.delete')> -1">{{
                      $t('device.delete')
                    }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :page-size="pageSize"
                :page-sizes="[10, 20,50]"
                :total="fenceTotal"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                fixed="right"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>

            <el-dialog :title="$t('device.devices')" center v-model="listDialog" width="70%">
              <div style="padding-bottom:60px;">
                <el-form :model="listForm" :inline="true" ref="searchForm">
                  <el-form-item label="设备IMEI">
                    <el-input :placeholder="$t('notice.enter_imei')" v-model="listForm.imei"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('index.device_type')" prop="type">
                    <el-select clearable :placeholder="$t('index.device_type')" v-model="listForm.type">
                      <el-option
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                          v-for="item in deviceType"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('device.device_status')" prop="online">
                    <el-select :placeholder="$t('device.device_status')" v-model="listForm.online">
                      <el-option :label="$t('device.online')" value="1"></el-option>
                      <el-option :label="$t('device.offline')" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="addSearch" type="primary">{{ $t('device.inquire') }}</el-button>
                    <el-button @click="resetSearch">{{ $t('device.reset') }}</el-button>
                  </el-form-item>
                </el-form>
                <el-table :data="deviceData" ref="deviceTable">
                  <el-table-column :label="$t('index.device')" property="imei" width="150"></el-table-column>
                  <el-table-column :label="$t('index.device_type')" property="type"></el-table-column>
                  <el-table-column :label="$t('device.device_status')" property="online"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" property="name"></el-table-column>
                  <el-table-column :label="$t('device.creation_time')" property="create_at"></el-table-column>
                </el-table>
                <el-pagination
                    :page-size="listForm.size"
                    :total="listCount"
                    @current-change="handleCurrentChangeList"
                    @size-change="handleSizeChangeList"
                    layout="total, prev, pager, next, jumper"
                ></el-pagination>
              </div>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import client from "@/model/client";
import fence from "@/model/fence";
import device from "@/model/device";

export default {
  name: "groupAlarm",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      fenceTotal: 0,
      formInline: {
        name: "",
        type: "",
        level: "",
        client_id: []
      },
      clientData: [],
      fenceData: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      listDialog: false,
      deviceData: [],
      listCount: 0,
      listForm: {
        imei: "",
        type: "",
        online: "",
        page: 1,
        size: 10
      }
    };
  },
  async mounted() {
    // await this.clientList();
    await this.fenceList();
    await this.deviceTypeArr();
  },

  methods: {
    async clientList() {
      const ret = await client.list({
        keywords: ""
      });
      if (ret.code === 200) {
        this.clientData = ret.data;
      }
    },
    async fenceList() {
      const ret = await fence.list({
        source: 1,
        name: this.formInline.name,
        type: this.formInline.type,
        level: this.formInline.level,
        client_id: this.formInline.client_id.join(","),
        page: this.currentPage,
        size: this.pageSize
      });
      if (ret.code === 200) {
        this.fenceData = ret.data.list;
        this.fenceTotal = ret.data.count;
      }
    },
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async deviceList() {
      const ret = await device.group(this.listForm);
      if (ret.code === 200) {
        this.deviceData = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    newFence() {
      this.$router.push({
        path: "/group/new_fence",
        query: {
          source: 1,
          id: 0
        }
      });
    },
    handleList(imei_list) {
      this.listForm.imei = imei_list;
      this.listDialog = true;
      this.deviceList();
    },
    addSearch() {
      this.deviceList();
    },
    resetSearch() {
      this.$refs.searchForm.resetFields();
      this.deviceList();
    },
    handleEdit(id) {
      this.$router.push({
        path: "/group/new_fence",
        query: {
          source: 1,
          id: id
        }
      });
    },
    handleRemove(id) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await fence.remove({
              id: id
            });
            if (ret.code === 200) {
              this.$message.success(this.$t('device.delete_success'));
              this.fenceList();
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    onSubmit() {
      this.fenceList();
    },
    handleReset() {
      this.formInline = {
        name: "",
        type: "",
        level: "",
        client_id: []
      };
      this.fenceList();
    },
    handleNodeClick(node) {
      const index = this.formInline.client_id.indexOf(node.id);
      if (index < 0) {
        this.formInline.client_id.push(node.id);
      } else {
        this.formInline.client_id.splice(index, 1);
      }
      this.handleChildren(node.children);
      this.fenceList();
    },
    handleChildren(data) {
      if (data.length > 0) {
        data.forEach(v => {
          const key = this.formInline.client_id.indexOf(v.id);
          if (key < 0) {
            this.formInline.client_id.push(v.id);
          } else {
            this.formInline.client_id.splice(key, 1);
          }
          this.handleChildren(v.children);
        });
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fenceList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fenceList();
    },
    handleSizeChangeList(val) {
      this.listForm.size = val;
      this.deviceList();
    },
    handleCurrentChangeList(val) {
      this.listForm.size = val;
      this.deviceList();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-table {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
