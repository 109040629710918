import request from '../utils/request'

export default {
  async info (data) {   // 获取设备围栏详情
    const ret = await request('/v1/fence/info', data,'POST')
    return ret
  },
  async amap () {   // 获取高德围栏配置信息
    const ret = await request('/v1/fence/amap', {},'POST')
    return ret
  },
  async operate (data) {   // 新增/编辑围栏信息
    const ret = await request('/v1/fence/operate', data,'POST')
    return ret
  },
  async remove (data) {   // 删除围栏信息
    const ret = await request('/v1/fence/remove', data,'POST')
    return ret
  },
  async list (data) {   // 获取围栏列表
    const ret = await request('/v1/fence/list', data,'POST')
    return ret
  },
}
