import request from '../utils/request'

export default {
    async list(data) {   // 获取设备列表
        return await request('/v1/device/list', data, 'POST')
    },
    async export(data) {   // 获取导出
        return await request('/v1/device/export', data, 'POST')
    },
    async info(data) {   // 获取设备详情
        return await request('/v1/device/info', data, 'POST')
    },
    async config(data) {   // 获取设备配置信息
        return await request('/v1/device/config', data, 'POST')
    },
    async upload(data) {   // 添加设备信息
        return await request('/v1/device/upload', data, 'POST')
    },
    async batchUpload(data) {    // 批量添加设备信息
        return await request('/v1/device/batch_upload', data, 'POST')
    },
    async allot(data) {    // 分配手环
        return await request('/v1/device/allot', data, 'POST')
    },
    async map(data) {    // 获取设备地图列表
        return await request('/v1/device/map', data, 'POST')
    },
    async group(data) {    // 获取群控设备列表
        return await request('/v1/device/group', data, 'POST')
    },
    async setting(data) {    // 获取设备参数
        return await request('/v1/device/setting', data, 'POST')
    },
    async saveSetting(data) {    // 保存设备参数
        return await request('/v1/device/save_setting', data, 'POST')
    },
    async uploadFile(data) {
        return await request('/common/upload', data, 'POST', 'multipart/form-data')
    },
    async batchRemove(data) {        // 批量删除设备
        return await request('/v1/device/remove', data, 'POST')
    },
    async notice(data) {        // 设备通知
        return await request('/v1/device/notice', data, 'POST')
    },
    async detail(data) {        // 设备详情
        return await request('/v1/device/detail', data, 'POST')
    }
}
