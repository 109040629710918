import request from '../utils/request'

export default {
  async list (data) {   // 获取客户列表
    const ret = await request('/v1/client/list', data, 'POST')
    return ret
  },
  async operate (data) {    // 新增/编辑客户
    const ret = await request('/v1/client/operate', data, 'POST')
    return ret
  },
  async remove (data) {   // 删除客户
    const ret = await request('/v1/client/remove', data, 'POST')
    return ret
  },
  async user (data) {  // 新增客户管理员
    const ret = await request('/v1/client/user', data, 'POST')
    return ret
  },
  async userList (data) {   // 获取设备管理员账号列表
    const ret = await request('/v1/client/user_list', data, 'POST')
    return ret
  },
  async userRemove (data) {    // 删除管理用户
    const ret = await request('/v1/client/user_remove', data, 'POST')
    return ret
  },
  async resetUser (data) {    // 重置管理用户密码
    const ret = await request('/v1/client/reset', data, 'POST')
    return ret
  },
}
